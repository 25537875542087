<template>
  <div class="main-municipality">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Título, Diretriz, Comissões e Eixos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Fases e Limites de Delegados
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form
            @submit="submitForm"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card class="mb-12">
              <div class="responsibility">
                <span>Cadastro de Nova Conferência</span>
              </div>
            </v-card>

            <div style="display: flex">
              <v-img
                style="background-color: grey"
                type="image"
                max-width="300"
                max-height="300"
                :src="imageUrl"
              ></v-img>
              <div class="ml-5">
                <v-text-field
                  v-model="title"
                  :counter="100"
                  :rules="nameRules"
                  label="Titulo"
                  style="width: 700px"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="theme"
                  :counter="100"
                  :rules="nameRules"
                  label="Tema"
                  required
                ></v-text-field>
                <v-select
                  v-model="select"
                  :items="items"
                  :rules="[(v) => !!v || 'Vigência é obrigatório!']"
                  label="Vigência"
                  required
                ></v-select>
                <v-file-input
                  accept="image/*"
                  label="Adicione uma imagem"
                  v-model="image"
                  :rules="nameEixos"
                  @change="onFileChange"
                ></v-file-input>
                <v-checkbox
                  v-model="checkboxDiretriz"
                  label="Possui Diretrizes?"
                ></v-checkbox>
              </div>
            </div>

            <div class="responsibility">
              <span>Comissão/Função</span>
              <div class="line-title"></div>
            </div>

            <v-row>
              <v-col cols="12" md="2">
                <label for="quantity"></label>
                <v-text-field
                  id="quantity"
                  style="margin-top: -8px"
                  type="number"
                  :rules="numEixos"
                  min="0"
                  max="10"
                  label="Quantidade"
                  placeholder="Quantidade"
                  v-model="numCommissionFields"
                  @input="createCommissionInput"
                />
              </v-col>
              <v-col cols="12" md="8" class="sub-eixo-container">
                <div
                  class="input-container"
                  v-for="(input, index) in commissionFields"
                  :key="index"
                >
                  <div class="title-eixos eixos-news">
                    <label>0{{ index + 1 }}.</label>
                    <v-text-field
                      type="text"
                      name="eixo"
                      :rules="nameEixos"
                      v-model="input.text"
                      min="0"
                      :class="['input-' + (index + 1) + ' ' + 'my-input']"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="inputs-eixos">
              <div class="eixos-inputs"></div>
              <span class="line-title"></span>
            </div>
            <div class="responsibility">
              <span>Eixos e Sub-Eixos</span>
              <div class="line-title"></div>
            </div>

            <v-row>
              <v-col cols="12" md="2">
                <label for="quantity"></label>
                <v-text-field
                  style="margin-top: -8px"
                  placeholder="Quantidade"
                  label="Quantidade"
                  id="quantity"
                  type="number"
                  :rules="numEixos"
                  min="0"
                  max="10"
                  v-model="numInputs"
                  @input="createInputs"
                />
              </v-col>
              <v-col cols="12" md="10">
                <div v-for="(input, index) in inputs" :key="index">
                  <div class="title-eixos eixos-news">
                    <label>0{{ index + 1 }}</label>
                    <v-text-field
                      type="text"
                      name="eixo"
                      :rules="nameEixos"
                      v-model="input.text"
                      min="0"
                      :class="['input-' + (index + 1) + ' ' + 'my-input']"
                    />
                  </div>

                  <v-row>
                    <v-col cols="10" md="3">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                      >
                        <div>
                          <v-text-field
                            label="Sub-Eixos"
                            type="number"
                            :rules="subEixo"
                            min="0"
                            max="10"
                            v-model="input.numInputsAdicionais"
                            @input="createAdicionais(index)"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="10" md="7">
                      <div
                        class="title-sub-eixos sub-eixos-news"
                        v-for="(adicional, i) in input.adicionais"
                        :key="i"
                      >
                        <label>{{ index + 1 }}.{{ i + 1 }}:</label>
                        <v-text-field
                          type="text"
                          min="0"
                          :rules="nameEixos"
                          max="10"
                          v-model="adicional.text"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <div style="display: flex; justify-content: end">
              <v-btn color="primary" @click="nextStep"> Continuar </v-btn>
            </div>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form
            @submit="submitForm"
            ref="form2"
            v-model="valid"
            lazy-validation
          >
            <v-card class="mb-12">
              <div class="region">
                <div class="line-title"></div>
                <div>
                  <v-checkbox
                    v-model="checkboxMuni"
                    label="Possui Fase Municipal?"
                    required
                  ></v-checkbox>

                  <!-- v-if="checkboxMuni" -->
                  <div class="division" v-if="checkboxMuni">
                    <div class="responsibility">
                      <span>Fase Municipal</span>
                      <div class="line-title"></div>
                    </div>

                    <div class="grid-container-flex">
                      <div class="column-flex-template">
                        <h4>Prazo de lançamento</h4>
                        <label class="label-municipal" for="date-initial">
                          <v-text-field
                            class="date-field"
                            v-model="dateInitialMunicipal"
                            type="date"
                            :rules="dateRules"
                            label="Data de Início"
                            required
                          ></v-text-field>
                        </label>
                        <label class="label-municipal" for="date-end">
                          <v-text-field
                            class="date-field"
                            v-model="dateEndMunicipal"
                            :min="dateInitialMunicipal"
                            :rules="dateRules"
                            type="date"
                            label="Data de Fim"
                            required
                          ></v-text-field>
                        </label>
                        <label for="">
                          <h4>Limite de propostas</h4>
                          <v-select
                            v-model="selectMunicipal"
                            :items="selectMunicipalPro"
                            :rules="dateRules"
                            item-text="name"
                            item-value="id"
                            label="Tipo de limite"
                            required
                          ></v-select>
                          <v-text-field
                            v-model="proposalMunicipal"
                            type="number"
                            :rules="numberMunicipal"
                            label="Quantidade"
                            required
                          ></v-text-field>
                        </label>
                      </div>
                      <!-- ANCHOR - 'Para até' HTML -->
                      <div>
                        <div class="responsibility">
                          <span>Limite de Delegados por Município</span>
                        </div>

                        <v-row>
                          <v-col cols="12" md="2">
                            <v-text-field
                              id="quantity"
                              v-model="numCountyDelegate"
                              :rules="numberMunicipal"
                              label="Faixas"
                              type="number"
                              min="2"
                              max="10"
                              @input="createLimitCountyDelegate"
                              v-text-field
                            />
                          </v-col>
                          <v-col cols="12" md="10">
                            <v-row>
                              <v-col
                                class="row-flex-template"
                                v-for="(input, index) in inputsCountyDelegates"
                                :key="index"
                              >
                                <label
                                  class="label-delegate"
                                  for="for-to"
                                  v-if="index == 0"
                                >
                                  <span class="mr-4">0{{ index + 1 }}.</span>
                                  <v-text-field
                                    disabled
                                    v-model="initalValueDelegate"
                                  ></v-text-field>
                                  à
                                </label>
                                <label
                                  class="label-delegate"
                                  for="for-to"
                                  v-if="
                                    index != 0 &&
                                    index != inputsCountyDelegates.length - 1
                                  "
                                >
                                  <span class="mr-4">0{{ index + 1 }}.</span>
                                  <v-text-field
                                    disabled
                                    v-model="
                                      inputsCountyDelegates[index]
                                        .next_population_number
                                    "
                                  ></v-text-field>
                                  à
                                </label>

                                <label
                                  class="label-delegate"
                                  for="for-to"
                                  v-if="
                                    index == inputsCountyDelegates.length - 1 &&
                                    index != 0
                                  "
                                >
                                  <span class="mr-4">0{{ index + 1 }}.</span>
                                  <span
                                    :v-model="
                                      (input.population_number =
                                        inputsCountyDelegates[
                                          index
                                        ].next_population_number)
                                    "
                                    style="white-space: nowrap"
                                    >Acima de
                                  </span>
                                </label>

                                <label class="label-delegate" for="for-to">
                                  <v-text-field
                                    :disabled="
                                      index ==
                                        inputsCountyDelegates.length - 1 &&
                                      index != 0
                                    "
                                    v-model="input.population_number"
                                    @keyup="changeNextPopulationValue(index)"
                                    min="0"
                                    type="number"
                                    :rules="numberZero"
                                    required
                                  ></v-text-field>
                                  <span class="mr-4">habitantes</span>
                                </label>
                                <label class="label-delegate" for="population">
                                  <v-text-field
                                    class="mr-3"
                                    label="Titulares"
                                    v-model="input.delegate_number"
                                    min="0"
                                    type="number"
                                    :rules="numberZero"
                                    required
                                  ></v-text-field>
                                </label>
                                <label class="label-delegate" for="population">
                                  <v-text-field
                                    label="Suplentes"
                                    v-model="input.substitutes"
                                    min="0"
                                    type="number"
                                    :rules="numberZero"
                                    required
                                  ></v-text-field>
                                </label>
                              </v-col>
                            </v-row>
                          </v-col>
                          <div>
                            <div class="responsibility">
                              <span
                                >Limite de Delegados Conferências Livres</span
                              >
                            </div>
                            <v-row>
                              <v-col cols="12" md="2">
                                <v-text-field
                                  id="quantity"
                                  v-model="numInstituteDelegate"
                                  :rules="numberMunicipal"
                                  label="Faixas"
                                  type="number"
                                  min="2"
                                  max="10"
                                  @input="createLimitInstituteDelegate"
                                  v-text-field
                                />
                              </v-col>
                              <v-col cols="12" md="10">
                                <v-row>
                                  <v-col
                                    class="row-flex-template"
                                    v-for="(
                                      input, index
                                    ) in inputsInstituteDelegates"
                                    :key="index"
                                  >
                                    <label
                                      class="label-delegate"
                                      for="for-to"
                                      v-if="index == 0"
                                    >
                                      <span class="mr-4"
                                        >0{{ index + 1 }}.</span
                                      >
                                      <v-text-field
                                        disabled
                                        v-model="initalValueDelegate"
                                      ></v-text-field>
                                      à
                                    </label>
                                    <label
                                      class="label-delegate"
                                      for="for-to"
                                      v-if="
                                        index != 0 &&
                                        index !=
                                          inputsInstituteDelegates.length - 1
                                      "
                                    >
                                      <span class="mr-4"
                                        >0{{ index + 1 }}.</span
                                      >
                                      <v-text-field
                                        disabled
                                        v-model="input.next_participants_number"
                                      ></v-text-field>
                                      à
                                    </label>

                                    <label
                                      class="label-delegate"
                                      for="for-to"
                                      v-if="
                                        index ==
                                          inputsInstituteDelegates.length - 1 &&
                                        index != 0
                                      "
                                    >
                                      <span class="mr-4"
                                        >0{{ index + 1 }}.</span
                                      >
                                      <span
                                        :v-model="
                                          (input.participants_number =
                                            input.next_participants_number)
                                        "
                                        style="white-space: nowrap"
                                        >Acima de
                                      </span>
                                    </label>

                                    <label class="label-delegate" for="for-to">
                                      <v-text-field
                                        :disabled="
                                          index ==
                                            inputsInstituteDelegates.length -
                                              1 && index != 0
                                        "
                                        v-model="input.participants_number"
                                        @keyup="
                                          changeNextParticipantsValue(index)
                                        "
                                        min="0"
                                        type="number"
                                        :rules="numberZero"
                                        required
                                      ></v-text-field>
                                      <span class="mr-4">participantes</span>
                                    </label>
                                    <label
                                      class="label-delegate"
                                      for="population"
                                    >
                                      <v-text-field
                                        class="mr-3"
                                        label="Titulares"
                                        v-model="input.delegate_number"
                                        min="0"
                                        type="number"
                                        :rules="numberZero"
                                        required
                                      ></v-text-field>
                                    </label>
                                    <label
                                      class="label-delegate"
                                      for="population"
                                    >
                                      <v-text-field
                                        label="Suplentes"
                                        v-model="input.substitutes"
                                        min="0"
                                        type="number"
                                        :rules="numberZero"
                                        required
                                      ></v-text-field>
                                    </label>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="line-title"></span>

                <div>
                  <v-checkbox
                    v-model="checkboxMacro"
                    label="Possui Fase Macrorregional?"
                    required
                  ></v-checkbox>

                  <div class="division" v-if="checkboxMacro">
                    <div class="responsibility">
                      <span>Fase Macrorregional</span>
                      <div class="line-title"></div>
                    </div>

                    <div class="grid-container-flex">
                      <div class="column-flex-template">
                        <h4>Prazo de lançamento</h4>
                        <label class="label-municipal" for="date-initial">
                          <v-text-field
                            class="date-field"
                            v-model="dateInitialMacro"
                            type="date"
                            :min="dateEndMunicipal"
                            :rules="dateRules"
                            label="Data de Início"
                            required
                          ></v-text-field>
                        </label>
                        <label class="label-municipal" for="date-end">
                          <v-text-field
                            class="date-field"
                            v-model="dateEndMacro"
                            type="date"
                            :min="dateInitialMacro"
                            :rules="dateRules"
                            label="Data de Fim"
                            required
                          ></v-text-field>
                        </label>
                        <h4>Limite de Propostas</h4>
                        <v-select
                          v-model="selectMacro"
                          :items="selectMacroPro"
                          :rules="dateRules"
                          item-text="name"
                          item-value="id"
                          label="Tipo de Limite"
                          required
                        ></v-select>
                        <v-text-field
                          v-model="proposalMacro"
                          type="number"
                          :rules="numberMunicipal"
                          label="Quantidade"
                          required
                        ></v-text-field>
                      </div>
                      <!-- ANCHOR - 'Para até' HTML -->
                      <div>
                        <div class="responsibility-t">
                          <span>Quantidade de Delegados por Macro</span>
                        </div>

                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            class="row-flex-template-macro"
                            v-for="(
                              input, index
                            ) in inputsRegionalNucleusDelegates"
                            :key="index"
                          >
                            <div class="grid-delegate">
                              <label
                                class="label-delegate label-macro"
                                for="for-to"
                              >
                                0{{ index + 1 }}.
                                <v-text-field
                                  :value="input.macro_name"
                                  min="0"
                                  type="text"
                                  disabled
                                  required
                                ></v-text-field>
                              </label>

                              <label class="label-delegate" for="population">
                                <v-text-field
                                  v-model="input.delegate_number"
                                  class="mr-3"
                                  label="Titulares"
                                  min="0"
                                  type="number"
                                  :rules="numberZero"
                                  required
                                ></v-text-field>
                              </label>
                              <label class="label-delegate" for="population">
                                <v-text-field
                                  v-model="input.substitutes"
                                  label="Suplentes"
                                  min="0"
                                  type="number"
                                  :rules="numberZero"
                                  required
                                ></v-text-field>
                              </label>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="line-title"></span>
              </div>
              <div class="region">
                <div class="division">
                  <div class="responsibility">
                    <span>Fase Estadual</span>
                    <div class="line-title"></div>
                  </div>

                  <div class="grid-container-flex">
                    <div class="column-flex-template">
                      <h4>Prazo de lançamento</h4>

                      <label class="label-municipal">
                        <v-text-field
                          class="date-field"
                          v-model="dateInitialEstadual"
                          type="date"
                          :min="dateEndMacro || dateEndMunicipal"
                          :rules="dateRules"
                          label="Data de Início"
                          required
                        ></v-text-field>
                      </label>

                      <label class="label-municipal">
                        <v-text-field
                          class="date-field"
                          v-model="dateEndlEstadual11"
                          :min="dateInitialEstadual"
                          type="date"
                          :rules="dateRules"
                          label="Data de Fim"
                          required
                        ></v-text-field>
                      </label>
                      <h4>Limite de Propostas</h4>
                      <v-select
                        v-model="selectEstadual"
                        :items="selecEstadualPro"
                        :rules="dateRules"
                        item-value="id"
                        item-text="name"
                        label="Tipo de Limite"
                        required
                      ></v-select>

                      <v-text-field
                        v-model="proposalEstadual"
                        type="number"
                        :rules="numberMunicipal"
                        label="Quantidade"
                        required
                      ></v-text-field>
                    </div>
                    <v-row class="ml-5">
                      <v-col cols="12" md="12">
                        <div class="responsibility">
                          <span
                            >Limitação de Delegados Eleitos para Fase
                            Nacional</span
                          >
                        </div>
                        <div class="grid-delegate space-button">
                          <label class="label-delegate" for="for-to">
                            Delegados titulares
                            <v-text-field
                              v-model="inputDelegate"
                              min="0"
                              type="number"
                              :rules="numberMunicipal"
                              required
                            ></v-text-field>
                          </label>

                          <label class="label-delegate" for="population">
                            Delegados Suplentes
                            <v-text-field
                              v-model="inputDelegate1"
                              min="0"
                              type="number"
                              :rules="numberMunicipal"
                              required
                            ></v-text-field>
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>

              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
            </v-card>

            <div style="display: flex; justify-content: end">
              <v-btn color="primary" @click="e1 = 1" class="mr-1">
                Voltar
              </v-btn>
              <v-btn color="success" @click="submitForm"> CADASTRAR </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <!-- Botões para validações dev -->
    <!-- <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
        Validar
      </v-btn>

      <v-btn color="error" class="mr-4" @click="reset">
        Resetar Formulário
      </v-btn>

      <v-btn color="warning" class="mr-4" @click="resetValidation">
        Resetar a Validação
      </v-btn> -->
  </div>
</template>
<script>
import axios from "@/axios";

export default {
  data: () => ({
    regionalNucleusHealthList: [],
    inputsRegionalNucleusDelegates: [],
    initalValueDelegate: 0,
    numCountyDelegate: 0,
    numInstituteDelegate: 0,
    inputsCountyDelegates: [],
    inputsInstituteDelegates: [],
    verifyIfRulesIsValidated: false,
    dialog: false,
    dialogDelete: false,
    errorLogin: [],
    valid: true,
    theme: "",
    title: "",
    numFields: 0,
    textFields: [],
    validatedEixos: [],
    selectEstadual: "",
    displayError: {
      display: false,
      message: [],
    },

    headersEixos: [
      { text: "Titulo", value: "title" },
      { text: "Ações", value: "actions", sortable: false },
    ],

    // #region
    dateInitialEstadual: "",
    dateEndlEstadual11: "",
    dateEndlEstadual: "",
    proposalEstadual: "",

    proposalMunicipal: "",
    dateInitialMunicipal: "",
    immutableDateInitialMunicipal: "",
    dateEndMunicipal: "",

    dateInitialMacro: "",
    dateEndMacro: "",
    proposalMacro: "",

    snackbar: false,

    inputDelegate: null,
    inputDelegate1: null,
    newInputs: [],

    //Imagem
    image: null,
    imageUrl: null,
    archive: {
      archive_name: "",
      archive_extension: "",
      archive_blob: "",
      size: "",
    },

    // Variavéis de comissão/função
    numCommissionFields: 0,
    commissionFields: [],

    numInputs: 0,
    inputs: [],

    checkboxMuni: true,
    checkboxDiretriz: false,
    checkboxMacro: true,
    btnStatus: "Acessar",
    carregamento: false,
    desativado: false,
    items: ["Ativado", "Desativado"],

    //Select - Macro - Municipal - Estadual
    selectMunicipalPro: [],
    selectMunicipal: null,

    selectMacroPro: [],
    selectMacro: null,

    selecEstadualPro: [],
    selecEstadual: null,

    // ANCHOR - Regras
    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length >= 3) || "O nome deve ter no mínimo 3 caracteres.",
    ],

    nameEixos: [(v) => !!v || "Campo obrigatório!"],

    subEixo: [(v) => v <= 10 || "O número é acima do esperado"],

    numberMunicipal: [
      (v) => !!v || "Campo obrigatório!",
      (v) => v > 0 || "O número é abaixo do esperado",
    ],

    numberZero: [
      (v) => !!v || "Campo obrigatório!",
      (v) => v >= 0 || "O número é abaixo do esperado",
    ],

    numEixos: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v > 0) || "O número é abaixo do esperado",
      (v) => (v && v <= 10) || "O número é acima do esperado",
    ],

    dateRules: [(v) => !!v || "Campo obrigatório!"],

    compareRules: [
      (v) =>
        (v && v < this.lineOneMunicipal) ||
        "O valor não pode ser maior que o primeiro campo",
    ],

    e1: 1,
    select: null,
    errorConfe: "",
    message: "",
    formRequest: new FormData(),
    formData: {
      conference: {
        title: null,
        vigency: null,
        conference_theme: null,
        initial_county_phase_date: null,
        final_county_phase_date: null,
        max_proposal_number_county_phase: null,
        initial_macro_phase_date: null,
        final_macro_phase_date: null,
        max_proposal_number_macro_phase: null,
        initial_state_phase_date: null,
        final_state_phase_date: null,
        max_proposal_number_state_phase: null,
        state_stage_delegates_titular_number: null,
        state_stage_delegates_substitutes_number: null,
        conference_proposal_county_standard_id: null,
        conference_proposal_macro_standard_id: null,
        conference_proposal_state_standard_id: null,
      },
      axie: [],

      roles: [],
      rules: [],
    },
    // #endregion
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    /**
     * ANCHOR - endData Watch
     * toda vez que as variaveis forem atualizadas, irá rodar a função endDataIsChange
     */
    dateEndMunicipal: "endDataIsChange",
    dateInitialMunicipal: "endDataIsChange",
  },

  methods: {
    changeNextParticipantsValue(index) {
      let key_array = index + 1;
      if (key_array in this.inputsInstituteDelegates) {
        this.inputsInstituteDelegates[index + 1].next_participants_number =
          Number(this.inputsInstituteDelegates[index].participants_number) + 1;
      }
    },
    changeNextPopulationValue(index) {
      let key_array = index + 1;
      if (key_array in this.inputsCountyDelegates) {
        this.inputsCountyDelegates[index + 1].next_population_number =
          Number(this.inputsCountyDelegates[index].population_number) + 1;
      }
    },
    nextStep() {
      if (this.$refs.form.validate() == true) {
        this.e1 = 2;
      }
    },
    validate() {
      this.$refs.form.validate();
    },

    // ANCHOR imagem

    onFileChange() {
      // Transformando IMG -> Blob
      this.imageUrl = URL.createObjectURL(
        new Blob([this.image], { type: "image/jpeg" })
      );
      this.archive.archive_name = this.image.name;
      this.archive.archive_extension = this.image.type;
      this.archive.archive_blob = this.imageUrl;
      this.archive.size = this.image.size;
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    // ANCHOR verification
    verification() {
      //Verifica se o próximo campo é maior que o anterior. Validação ocorre somente nos limites de delegados da fase municipal
      if (this.checkboxMuni) {
        if (this.inputsCountyDelegates.length > 1) {
          this.inputsCountyDelegates.forEach((element, index) => {
            if (index != 0) {
              if (
                Number(element.population_number) <
                Number(this.inputsCountyDelegates[index - 1].population_number)
              ) {
                this.$swal({
                  icon: "error",
                  title: "Preencha corretamente...",
                  text: `${
                    index - 1
                  }-${index} Quantidade de Delegados por Município ${
                    element.population_number
                  } precisa ser maior que ${
                    this.inputsCountyDelegates[index - 1].population_number
                  }`,
                });
                return;
              }
            }
          });
        }

        if (this.inputsInstituteDelegates.length > 1) {
          this.inputsInstituteDelegates.forEach((element, index) => {
            if (index != 0) {
              if (
                Number(element.participants_number) <
                Number(
                  this.inputsInstituteDelegates[index - 1].participants_number
                )
              ) {
                this.$swal({
                  icon: "error",
                  title: "Preencha corretamente...",
                  text: `${
                    index - 1
                  }-${index} Quantidade de Delegados por Conferência Livre ${
                    element.participants_number
                  } precisa ser maior que ${
                    this.inputsInstituteDelegates[index - 1].participants_number
                  }`,
                });
                return;
              }
            }
          });
        }
      }
      //Montando as regras de validação de quantidade de delegados por habitantes e participantes.
      //O último valor do array sempre deverá ser "maior que" e o resto "menor ou igual"
      this.inputsCountyDelegates.forEach((element, index) => {
        if (index != this.inputsCountyDelegates.length - 1) {
          element.condition = "<=";
        } else {
          element.condition = ">";
        }
      });

      this.inputsInstituteDelegates.forEach((element, index) => {
        if (index != this.inputsInstituteDelegates.length - 1) {
          element.condition = "<=";
        } else {
          element.condition = ">";
        }
      });

      this.formData.rules = [];
      this.formData.rules = this.formData.rules.concat(
        this.inputsCountyDelegates,
        this.inputsInstituteDelegates,
        this.inputsRegionalNucleusDelegates
      );
    },

    submitForm() {
      this.verification();

      if (this.$refs.form2.validate() != true) {
        this.$swal({
          icon: "error",
          title: "Erro ao cadastrar conferência",
          text: "Campo obrigatório não preenchido!",
        });

        return this.$refs.form.validate();
      }

      this.formData.conference.title = this.title.trimStart().trimEnd();
      this.formData.conference.vigency = this.select;
      this.formData.conference.conference_theme = this.theme
        .trimStart()
        .trimEnd();
      this.formData.conference.initial_state_phase_date =
        this.dateInitialEstadual;
      this.formData.conference.final_state_phase_date = this.dateEndlEstadual11;
      this.formData.conference.max_proposal_number_state_phase =
        this.proposalEstadual;
      this.formData.conference.initial_county_phase_date =
        this.dateInitialMunicipal;
      this.formData.conference.final_county_phase_date = this.dateEndMunicipal;
      this.formData.conference.max_proposal_number_county_phase =
        this.proposalMunicipal;
      this.formData.conference.initial_macro_phase_date = this.dateInitialMacro;
      this.formData.conference.final_macro_phase_date = this.dateEndMacro;
      this.formData.conference.max_proposal_number_macro_phase =
        this.proposalMacro;
      this.formData.conference.HasGuidelines = this.checkboxDiretriz;
      this.formData.conference.conference_proposal_county_standard_id =
        this.selectMunicipal;
      this.formData.conference.conference_proposal_macro_standard_id =
        this.selectMacro;
      this.formData.conference.conference_proposal_state_standard_id =
        this.selectEstadual;
      this.formData.conference.state_stage_delegates_titular_number =
        this.inputDelegate1;
      this.formData.conference.state_stage_delegates_substitutes_number =
        this.inputDelegate;

      this.formData.roles = this.commissionFields.map(({ text: name }) => ({
        name,
      }));
      this.newInputs = this.inputs.map(({ text, adicionais, ...input }) => {
        const sub_axie = adicionais.map(({ text, ...sub_axie }) => {
          return { ...sub_axie, title: text };
        });
        return { ...input, title: text, sub_axie };
      });
      this.formData.axie = this.newInputs;
      this.formData.archive = this.archive;

      this.formRequest.append("file_logo", this.image);
      this.formRequest.append("formData", JSON.stringify(this.formData));
      const headers = { "Content-Type": "multipart/form-data" };

      axios
        .post(`conference`, this.formRequest, { headers })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Conferência criada com sucesso!",
            showConfirmButton: false,
            timer: 4000,
          }).then(() => {
            this.$router.push({ path: "/dashboard/conference" });
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao cadastrar conferência",
            text: err.data.message,
          });
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },

    mountMacroInputs() {
      axios.get(`/regional-nucleus-health/`).then((response) => {
        this.createLimitRegionalNucleusHealthDelegate(response.data);
      });
    },

    createLimitRegionalNucleusHealthDelegate(regionalNucleuHealthList) {
      regionalNucleuHealthList.forEach((element) => {
        this.inputsRegionalNucleusDelegates.push({
          name: "macro",
          macro_name: element.name,
          condition: "==",
          delegate_number: "",
          substitutes: "",
        });
      });
    },

    createLimitCountyDelegate() {
      if (this.numCountyDelegate <= 0) {
        this.inputsCountyDelegates = [];
        return;
      }

      if (this.inputsCountyDelegates.length < this.numCountyDelegate) {
        for (
          let i = this.inputsCountyDelegates.length;
          i < this.numCountyDelegate;
          i++
        ) {
          this.inputsCountyDelegates.push({
            name: "municipal",
            population_number: "",
            condition: "",
            delegate_number: "",
            substitutes: "",
            next_population_number: "",
          });
        }
      } else if (this.inputsCountyDelegates.length > this.numCountyDelegate) {
        this.inputsCountyDelegates.length = this.numCountyDelegate;
      }
    },

    createLimitInstituteDelegate() {
      if (this.numInstituteDelegate <= 0) {
        this.inputsInstituteDelegates = [];
        return;
      }

      if (this.inputsInstituteDelegates.length < this.numInstituteDelegate) {
        for (
          let i = this.inputsInstituteDelegates.length;
          i < this.numInstituteDelegate;
          i++
        ) {
          this.inputsInstituteDelegates.push({
            name: "livre",
            participants_number: "",
            condition: "",
            delegate_number: "",
            substitutes: "",
            next_participants_number: "",
          });
        }
      } else if (
        this.inputsInstituteDelegates.length > this.numInstituteDelegate
      ) {
        this.inputsInstituteDelegates.length = this.numInstituteDelegate;
      }
    },
    createInputs() {
      if (this.numInputs <= 0) {
        this.inputs = [];
        return;
      }
      if (this.inputs.length < this.numInputs) {
        for (let i = this.inputs.length; i < this.numInputs; i++) {
          this.inputs.push({
            text: "",
            numInputsAdicionais: 0,
            adicionais: [],
          });
        }
      } else if (this.inputs.length > this.numInputs) {
        this.inputs.length = this.numInputs;
      }
    },

    createCommissionInput() {
      if (this.numCommissionFields <= 0) {
        this.commissionFields = [];
        return;
      }
      if (this.commissionFields.length < this.numCommissionFields) {
        for (
          let i = this.commissionFields.length;
          i < this.numCommissionFields;
          i++
        ) {
          this.commissionFields.push({ text: "" });
        }
      } else if (this.commissionFields.length > this.numCommissionFields) {
        this.commissionFields.length = this.numCommissionFields;
      }
    },

    createAdicionais(index) {
      if (this.inputs[index].numInputsAdicionais <= 0) {
        return (this.inputs[index].adicionais = []);
      }
      if (
        this.inputs[index].adicionais.length <
        this.inputs[index].numInputsAdicionais
      ) {
        for (
          let i = this.inputs[index].adicionais.length;
          i < this.inputs[index].numInputsAdicionais;
          i++
        )
          this.inputs[index].adicionais.push({ text: "" });
      } else if (
        this.inputs[index].adicionais.length >
        this.inputs[index].numInputsAdicionais
      ) {
        this.inputs[index].adicionais.length =
          this.inputs[index].numInputsAdicionais;
      }
    },

    getSelection() {
      axios.get("/conference-proposal-standard").then((res) => {
        this.selectMunicipalPro = res.data;
        this.selectMacroPro = res.data;
        this.selecEstadualPro = res.data;
      });
    },

    /**
     * ANCHOR - endData Function
     * Faz a troca de datas automaticamente, caso a data de final seja anterior que a
     * data de inicio, a data final será trocada para a mesma que a data inicial.
     */
    endDataIsChange() {
      if (
        new Date(this.dateEndMunicipal) < new Date(this.dateInitialMunicipal)
      ) {
        this.dateEndMunicipal = this.dateInitialMunicipal;
      }
    },
  },

  mounted() {
    this.getSelection();
    this.mountMacroInputs();
  },
};
</script>

<style>
.date-field {
  font-size: 22px !important;
}
.table-all {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.button-add {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.card-table-input-eixo {
  margin: 10px 30px;
}

.card-table {
  border: solid 1px blue;
  width: 500px;
  height: auto;
}

.card-table header {
  height: 0px !important;
}

.card-table-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid 1px green;
  height: 60px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 75%,
    rgba(0, 212, 255, 1) 100%
  );
}

.space-button {
  margin-bottom: 20px;
}

.title-eixoss {
  width: 10px !important;
}

.card-table-header span {
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
}

.region {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.label-macro input {
  width: 130px !important;
}

.sub-eixos-div {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.sub-eixos-div label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-eixos-div label div {
  width: 50px;
}

.m-5 {
  margin: 20px;
}

.input-adicional {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-adicional label {
  margin-right: 15px;
}

.input-adicional div {
  width: 200px;
}

.title-sub-eixos {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  margin-top: -1.5%;
  margin-left: -5%;
  margin-bottom: -5px;
}

.title-eixos {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  margin-top: -1.5%;
  margin-bottom: -5px;
}

.title-eixos label {
  margin-right: 5px;
}

.label-municipal {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.label-municipal > div {
  width: 250px;
}

.eixos-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.grid-container-flex {
  display: flex;
  justify-content: start;
  align-items: start;
}

.eixos-eixos {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.eixos-news {
  width: 100% !important;
}
.sub-eixos-news {
  width: 145% !important;
}

.eixos-inputs span {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 18px;
}

.eixos-inputs input {
  width: 250px;
  border-bottom: 1px solid #3123;
}

.eixos-inputs input:focus {
  outline: none;
}

.inputs-eixos {
  display: flex;
  flex-direction: column;
}

.inputs-eixos input:first-child {
  border-bottom: 1px solid #3123;
  width: 40px;
  margin-left: 10px;
}

.label-delegate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.label-delegate input {
  margin: 0 8px;
  width: 100px;
}

.grid-delegate {
  display: flex;
  flex-direction: row;
}

.division {
  margin-bottom: 40px;
}

.label-delegate input,
.label-municipal input {
  border-bottom: solid 1px #3123;
}

.column-flex-template {
  margin-right: 2%;
  width: 20%;
  display: flex;
  flex-direction: column;
}

.row-flex-template {
  display: flex;
  margin-bottom: -40px;
  flex-direction: row;
}
.row-flex-template-macro {
  margin-bottom: -40px;
}
.input-width {
  width: 5px;
  min-width: 2px !important;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.input-container {
  width: 100%;
}

.responsibility {
  margin-bottom: 20px;
  height: 28px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  margin-top: 20px;
}

.responsibility-t {
  font-size: 18px;
  font-weight: 500;
}

.responsibility span {
  font-weight: 500;
  width: auto;
  margin-right: 25px;
}

.line-title {
  width: 100%;
  height: 2px;
  background-color: rgb(71, 68, 68);
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal > button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}
</style>
